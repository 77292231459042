import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './components/product-dialog/product-dialog.component';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hidromuk';

  isBrowser: boolean;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  public productsCategories = [];
  public subcategorias = [];
  public produtos: { nome: string, categoria: string, data: { [x: string]: string }, imagem: string }[] = [];
  public menuOpen = false;
  public categorySelected = 'TODOS';
  mailFormGorup: FormGroup;
  public processing: boolean;
  private jsonURL = '/assets/produtos.json';
  private jsonData;

  constructor(@Inject(PLATFORM_ID) platformId: string, private breakpointObserver: BreakpointObserver, private http: HttpClient, private matDialog: MatDialog) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.mailFormGorup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
    });
  }

  get email(): AbstractControl {
    return this.mailFormGorup.get('email');
  }

  get name(): AbstractControl {
    return this.mailFormGorup.get('name');
  }

  get phone(): AbstractControl {
    return this.mailFormGorup.get('phone');
  }

  get message(): AbstractControl {
    return this.mailFormGorup.get('message');
  }

  get all(): boolean {
    return this.categorySelected === 'TODOS';
  }

  public getJSON(): Observable<any> {
    if (this.isBrowser) {
      return this.http.get(this.jsonURL);
    } else {
      return this.http.get('http://localhost:4000/assets/produtos.json');
    }
  }

  ngOnInit(): void {
    this.getJSON().subscribe(data => {
      this.jsonData = data;
      this.productsCategories = data.categorias;
      this.subcategorias = data.subcategorias;
      this.produtos = this.all ? data.produtos : data.produtos.filter(prod => prod.categoria.toLowerCase() === this.categorySelected.toLowerCase());
    });
  }

  togleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  selectCategory(cat: string): void {
    this.categorySelected = cat;
    if (this.categorySelected === 'TODOS') {
      this.produtos = this.jsonData.produtos;

    } else {
      this.produtos = this.jsonData.produtos.filter(prod => prod.categoria.toLowerCase() === this.categorySelected.toLowerCase());
    }
  }

  showProductData(data): void {
    this.matDialog.open(ProductDialogComponent, { data, minHeight: 542, maxWidth: 875, width: '100%', panelClass: 'custom-container' });
  }

  sendMail(): void {
    if (!this.mailFormGorup.valid) {
      const controles = Object.keys(this.mailFormGorup.controls);
      for (const cont of controles) {
        this.mailFormGorup.controls[cont].markAllAsTouched();
      }

      return;
    }
    this.processing = true;
    this.http.post('/mailer.php', this.mailFormGorup.value).subscribe(() => {

      this.matDialog.open(MessageDialogComponent, { data: 'Mensagem enviada', width: '320px', maxWidth: 420 });
      this.mailFormGorup.reset();
      this.processing = false;
    }, () => {
      this.processing = false;
      this.matDialog.open(MessageDialogComponent, {
        data: 'Ocorreu um erro ao enviar a mensagem. Tente novamente mais tarde', width: '320px', maxWidth: 420
      });
    });
  }
}
