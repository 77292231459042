<div class="product-dialog">
  <div class="product-image">
    <img [src]="product.imagem" alt="any">
  </div>
  <div class="product-data">
    <div class="product-name">{{product.nome}}</div>
    <div class="product-brand">{{product.categoria}}</div>
    <div class="product-info" *ngIf="isJson">
      <div class="data" *ngFor="let prdata of productData">
        <div class="data-name">{{ prdata.name }}:</div>
        <div class="data-value">{{ prdata.value }}</div>
      </div>
    </div>
    <div class="product-info" *ngIf="!isJson">
      <div class="data">
        <div class="data-name">{{ product.data }}</div>
      </div>
    </div>
  </div>
</div>
