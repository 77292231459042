<header>
  <nav class="main-menu">
    <div class="menu-logo">
      <img src="/assets/images/logo.svg" alt="" />
    </div>
    <div class="menu-items">
      <div class="menu-logo items">
        <img src="/assets/images/logo_white.svg" alt="" />
      </div>
      <div class="divider-sliced-menu"></div>
      <a class="main-menu-item" href="#company">A EMPRESA</a>
      <a class="main-menu-item" href="#services">SERVIÇOS</a>
      <a class="main-menu-item" href="#equipment">EQUIPAMENTOS</a>
      <a class="main-menu-item" href="#order">ORÇAMENTO</a>
      <a class="main-menu-item" href="#contact">CONTATO</a>
      <button class="menu-button" (click)="togleMenu()"><i class="fas fa-bars"></i></button>
    </div>
  </nav>
  <div class="responsive-menu-items" [ngClass]="menuOpen?'open':''">
    <ul>
      <li>
        <a class="main-menu-item" href="#company" (click)="togleMenu()">A EMPRESA</a>
      </li>
      <li>
        <a class="main-menu-item" href="#services" (click)="togleMenu()">SERVIÇOS</a>
      </li>
      <li>
        <a class="main-menu-item" href="#equipment" (click)="togleMenu()">EQUIPAMENTOS</a>
      </li>
      <li>
        <a class="main-menu-item" href="#order" (click)="togleMenu()">ORÇAMENTO</a>
      </li>
      <li>
        <a class="main-menu-item" href="#contact" (click)="togleMenu()">CONTATO</a>
      </li>
    </ul>
  </div>
</header>
<div class="banner-wrapper">
  <carousel cellWidth="42vw" cellsToShow="1" style="height: 42vw !important;" *ngIf="isBrowser" [arrows]="!(isHandset$|async)">
    <div class="carousel-cell">
      <img src="assets/images/banner_01.png" alt="banner 1">
    </div>
    <div class="carousel-cell">
      <img src="assets/images/banner_02.png" alt="banner 2">
    </div>
  </carousel>
</div>
<div class="secondary-color-divider"></div>
<section class="the-company" id="company">
  <div class="divider-sliced-secondary"></div>
  <div class="company-logo">
    <img src="/assets/images/hidromuk.png" alt="" />
  </div>
  <div class="about-the-company">
    <div class="about-header">
      HIDROMUK
      <div class="header-deco"></div>
    </div>
    <div class="about-content">
      Fundada em 15 de Junho de 1985, a Hidromuk é especializada na
      venda e manutenção de equipamentos hidráulicos. Com uma
      equipe de profissionais treinados e altamente qualificados a
      empresa atende toda a região com um trabalho reconhecido pela
      qualidade e confiança dos seus clientes.
    </div>

  </div>

</section>
<section class="services-section" id="services">
  <div class="divider-sliced-white"></div>
  <div class="services-title">
    SERVIÇOS
    <div class="header-deco"></div>
  </div>
  <div class="services-wrapper">
    <div class="services">
      <div class="service-tile">
        <div class="service-title">
          MANUTENÇÃO PREVENTIVA
        </div>
        <div class="service-content">
          Somos especialistas
          na manutenção de
          equipamentos hidráulicos.
        </div>

      </div>
      <div class="service-tile">
        <div class="service-title">
          PROFISSIONAIS
          TREINADOS
        </div>
        <div class="service-content">
          A equipe Hidromuk é
          formada por profissionais
          altamente qualificados
          e preparados.
        </div>
      </div>
      <div class="service-tile">
        <ul>
          <li class="service-list"> Alongamento de chassi</li>
          <li> Alteração de entre eixos</li>
          <li>Adequação de guindastes</li>
          <li>Direção hidráulica</li>
          <li>Normas NR 12</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="divider-sliced-white-bottom"></div>
</section>
<section class="products-container" id="equipment">
  <div class="products-title">
    EQUIPAMENTOS
    <div class="header-deco"></div>
  </div>
  <div class="products-listing">
    <div class="product-filters">
      <div class="main-filters">
        <div class="main-filter" (click)="selectCategory(cat)" [ngClass]="categorySelected == cat ? 'selected':''" *ngFor="let cat of productsCategories">{{cat}}</div>
      </div>
      <div class="secondary-filters">
        <div class="secondary-filter" *ngFor="let sub of subcategorias">{{sub}}</div>
      </div>

    </div>
    <div class="products-display">
      <div class="product-card" *ngFor="let card of produtos" (click)="showProductData(card)">
        <div class="prodct-card-image">
          <img [src]="card.imagem" [alt]="card.nome">
        </div>
        <div class="product-card-footer">
          <div class="product-name">{{card.nome}}</div>
<!--          <div class="product-brand">TKA</div>-->
        </div>
      </div>
    </div>
  </div>
</section>
<section class="testimonials-section" hidden>
  <div class="divider-sliced-white testimonials"></div>
  <div class="testimonial">
    <img src="assets/images/quotes.png" alt="quotes">
    <div class="testimonial-content">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
      accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </div>
    <div class="testimonial-data">
      <div class="testimonial-name">NOME SOBRENOME</div>
      <div class="testimonial-charge">CARGO NA EMPRESA</div>
    </div>
  </div>
  <div class="divider-sliced-red-bottom"></div>
</section>
<section class="contact-section" id="order">
  <h3 class="contact-title">Solicite seu orçamento</h3>
  <div class="contact-infos-grid">
    <div class="whatsapp-wrapper">
      <a class="whatsapp-contact" href="https://wa.me/5546988021000">
        Clique aqui
        <i class="fab fa-whatsapp"></i>
      </a>
      <div class="right-hand">
        e entre em contato agora mesmo!
      </div>
    </div>
    <span class="divider-sided"></span>
    <div class="contact-numbers">
      <div class="phone-numbers">
        (46) 3523 4348
        <span>&#x25CF;</span>
        (46) 3524 4597
      </div>
      <div class="email-address">
        contato@hidromuk.com.br
      </div>
    </div>
  </div>
  <h3 class="email-contact-title">Se preferir, envie uma mensagem</h3>
  <form class="email-message-form" (ngSubmit)="sendMail()" [formGroup]="mailFormGorup">
    <input placeholder="nome" type="text" class="contact-input" name="name" formControlName="name">
    <mat-error *ngIf="name.errors?.required && name.touched">Campo necessário</mat-error>
    <input placeholder="telefone" mask="(00) 0000-0000||(00) 0 0000-0000" type="tel" class="contact-input" name="phone" formControlName="phone">
    <mat-error *ngIf="phone.errors?.required && phone.touched">Campo necessário</mat-error>
    <input placeholder="e-mail" type="email" class="contact-input" name="email" formControlName="email">
    <mat-error *ngIf="email.errors?.required && email.touched">Campo necessário</mat-error>
    <textarea placeholder="mensagem:" class="contact-input area" name="message" formControlName="message"></textarea>
    <mat-error *ngIf="message.errors?.required && message.touched">Campo necessário</mat-error>
    <div class="action-button">
      <button [disabled]="processing">Enviar</button>
    </div>
  </form>
</section>
<section class="google-maps-section" id="contact">
  <div class="company-details">
    <div class="center-content">
      <div class="hidromuk-logo">
        <img src="/assets/images/logo.svg">
      </div>
      <div class="phone-wrapper">
        <div class="phone-icon">
          <img src="/assets/images/contact_icon.png" alt="">
        </div>
        <div class="phone-numbers">
          (46) 3523 4348
          (46) 3524 4597
        </div>
      </div>
      <div class="address-wrapper">
        <div class="address-icon">
          <i class="fas fa-map-marker-alt"></i>
        </div>
        <div class="address-data">
          Rua Maranhão, 880 - B. Vila Nova
          Francisco Beltrão – PR
        </div>
      </div>
      <div class="social-buttons">
        <a href="https://www.facebook.com/hidromukfb" target="_blank"><i class="fab fa-facebook-square"></i></a>
        <a href="https://www.instagram.com/hidromuk/" target="_blank"><i class="fab fa-instagram"></i></a>
        <a href="mailto:contato@hidromuk.com.br" target="_blank"><i class="fas fa-envelope"></i> </a>
        <a href="https://wa.me/5546988021000" target="_blank"><i class="fab fa-whatsapp"></i> </a>
      </div>
    </div>
  </div>
  <div class="map-data">
    <iframe
      height="100%" width="100%"
      frameborder="0" style="border:0"
      src="https://www.google.com/maps/embed/v1/place?q=Rua%20Maranh%C3%A3o%2C%20880%20-%20B.%20Vila%20Nova-%20Francisco%20Beltr%C3%A3o%20-%20PR&key=AIzaSyCyQ59TToLpXd-440q_OK3GZJ6doyz4Q_M"
      allowfullscreen></iframe>
  </div>
</section>
<footer class="footer-section">
  <div class="desinger">designed by Raiz branding <img src="/assets/images/d_logo.png"></div>
</footer>
