import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public product: { nome: string, categoria: string, data: { [x: string]: string } | string, imagem: string }) {
  }

  get isJson(): boolean {
    return typeof this.product.data !== 'string';
  }

  get productData(): Array<any> {
    const strings = Object.keys(this.product.data);
    const products = [];
    for (const data of strings) {
      products.push({ value: [this.product.data[data]], name: data });
    }
    return products;

  }

  ngOnInit(): void {
  }

}
